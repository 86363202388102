import React from "react";
import useScreenSize from "../utils/useScreenSize";
import Content from "./Content";
import ContentMobile from "./ContentMobile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusSquare, faPenSquare} from "@fortawesome/free-solid-svg-icons";
import {supabase} from "../utils/supabase";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

export default function CapsuleAdmin({aktualita, deco, decoPos, data}) {

    const {width} = useScreenSize();
    const navigate = useNavigate();

    let daToast;

    async function askToDelete() {
        daToast = toast((t) => (
            <span className={'flex-column center-vertical'}>
                Opravdu chcete odstranit {aktualita ? "aktualitu" : "akci" }
                <span><b>{data.nadpis}</b>?</span>
                <div className={'flex-row center-horizontal margin-s'}>
                    <p className={'admin-dash-button'} onClick={deleteArticle}>
                        Ano
                    </p>
                    <p className={'admin-dash-button'} onClick={() => toast.dismiss(t.id)}>
                        Ne
                    </p>
                </div>
            </span>
        ), {
            duration: 8000,
        });
    }

    async function deleteArticle() {
		
		let what = aktualita ? "aktuality" : "akce";

        toast.loading('Ničím molekuly ' + what + '...', {
            id: daToast,
        });

        const { error } = await supabase
            .from(what)
            .delete()
            .eq('id', data.id)

        if (error) {
            toast.error((aktualita ? "Aktualita" : "Akce") + ' nemohla být smazána', {
                id: daToast,
            });
            console.log(error);
        } else {
            void deleteImage();
        }
    }

    async function deleteImage() {
		
		let what = aktualita ? "aktuality" : "akce";

        const imgPath = data.img.slice(data.img.indexOf(data.id, data.img.indexOf('/public/obrazky')))

        const { error } = await supabase
            .storage
            .from('obrazky')
            .remove([imgPath])

        if (error) {
            toast.error('Pozůstatky ' + what + ' nebyly smazány', {
                id: daToast,
            });
            console.log(error);
        } else {
            toast.success('Úspěšně odstraněno!', {
                id: daToast,
            });
            setTimeout(function () {
                navigate(0);
            }, 2000);
        }
    }

    return (
        <>
            {width <= 700 ?

                <div className={'blog-mobile-main box-shadow-l'}>
                    <ContentMobile data={data} demo={true}/>
                </div>

                :

                <div className={"blog-preview-capsule margin-m"}>
                    <div className={"blog-preview-main box-shadow-l"}>
                        <Content data={data} demo={true}/>
                        <div className={'flex-column center-vertical center-horizontal admin-akce-icony'}>
                            <FontAwesomeIcon icon={faPenSquare} />
                            <FontAwesomeIcon icon={faMinusSquare} onClick={(e) => {
                                void askToDelete();
                            }} />
                        </div>
                    </div>
                    {deco === true ?
                        <div className={decoPos === 'left' ? 'blog-preview-deco blog-preview-deco-left' : 'blog-preview-deco blog-preview-deco-right'} />
                        : null}
                </div>

            }
        </>
    );

}