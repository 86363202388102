import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {AnimatePresence, motion} from "framer-motion";
import {supabase} from "../komponenty/utils/supabase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import Gondola from "../komponenty/o-nas/Gondola";
import ModalShell from "../komponenty/o-nas/ModalShell";

function ONas() {

    const [prevLokomotiva, setPrevLokomotiva] = useState(0);
    const [nextLokomotiva, setNextLokomotiva] = useState(0);
    const [prevVagon, setPrevVagon] = useState(0);
    const [nextVagon, setNextVagon] = useState(0);

    const [lokomotivy, setLokomotivy] = useState([]);
    const [vagony, setVagony] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const [statStroje, setStatStroje] = useState(1);

    const closeModal = () => {
        document.body.style.overflowY = 'initial';
        setModalOpen(false)
    };
    const openModal = (data) => {
        document.body.style.overflowY = 'hidden';
        setModalOpen(true)
        setModalData(data);
    };

    useEffect(() => {
        void getLokomotivy();
        void getVagony();
        void statsStroje();
    }, []);

    async function statsStroje() {
        setStatStroje(await getLokomotivyCount() + await getVagonyCount())
    }

    async function getLokomotivyCount() {
        const { data, error } = await supabase
            .from('lokomotivy')
            .select('id');

        if (error) {
            console.log(error);
            return 0;
        } else {
            return data.length;
        }
    }

    async function getVagonyCount() {
        const { data, error } = await supabase
            .from('vagony')
            .select('id');

        if (error) {
            console.log(error);
            return 0;
        } else {
            return data.length;
        }
    }

    async function getLokomotivy() {
        const { data } = await supabase
            .from("lokomotivy")
            .select()
            .order('id');

        setLokomotivy(data);
        setNextLokomotiva(data[1].id);
        await checkInView('lokomotiva-', data);
    }

    async function getVagony() {
        const { data } = await supabase
            .from("vagony")
            .select()
            .order('id');

        setVagony(data);
        setNextVagon(data[0].id);
        await checkInView('vagon-', data);
    }

    async function checkInView(child, array) {

        for (let i = 0; i < array.length; i++) {

            let current = child + array[i].id;
            let next = current;
            let previous = current;

            if (array[i+1])
                next = child + array[i+1].id;
            if (array[i-1])
                previous = child + array[i-1].id;

            if (!document.getElementById(current) || !document.getElementById(next)) {
                window.setTimeout(() => {
                    checkInView(child, array);
                }, 100)
                return;
            }

            if (await isInView(document.getElementById(current))
                && !await isInView(document.getElementById(previous)))
            {
                await setPreviousGondola(previous, child);
            }

            if (await isInView(document.getElementById(current))
                && !await isInView(document.getElementById(next)))
            {
                await setNextGondola(next, child);
                break;
            }
        }
    }

    const setPreviousGondola = async (gondola, child) => {
        if (child === 'lokomotiva-') {
            setPrevLokomotiva(gondola.replace(child, ''));
        } else if (child === 'vagon-') {
            setPrevVagon(gondola.replace(child, ''));
        }
    }

    const setNextGondola = async (gondola, child) => {
        if (child === 'lokomotiva-') {
            setNextLokomotiva(gondola.replace(child, ''));
        } else if (child === 'vagon-') {
            setNextVagon(gondola.replace(child, ''));
        }
    }

    const isInView = async (el) => {
        let carousel = document.getElementById('carousel');
        return (await getPosStart(el) >= await getCarouselLeft(carousel) && await getPosEnd(el) <= await getCarouselRight(carousel));
    }
    
    const getPosStart = async (el) => {
        //console.log(el.getBoundingClientRect().left)
        return el.getBoundingClientRect().left;
    }
    
    const getPosEnd = async (el) => {
        return el.getBoundingClientRect().right;
    }


    const getCarouselLeft = async (carousel) => {
        return carousel.getBoundingClientRect().left;
    }

    const getCarouselRight = async (carousel) => {
        return carousel.getBoundingClientRect().right;
    }

    return (
        <>
            <Helmet>
                <title>O Nás | Posázavský Pacifik</title>
            </Helmet>
            <AnimatePresence
                initial={false}
                mode={'wait'}
            >
                {modalOpen && <ModalShell modalOpen={modalOpen} close={closeModal} data={modalData} />}
            </AnimatePresence>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div className={'flex-column center-horizontal'}>
                    <div className={'onas-header flex-row center-horizontal center-vertical'}>
                        <h1 className={'page-header'}>O Nás</h1>
                    </div>
                    <div className={'onas-popis-text'}>
                        <p className={'text-justify'}>
                            Posázavský Pacifik byl založen jako občanské sdružení, dne 21. dubna 1998. Mezi hlavní cíle této neziskové organizace od počátku patří propagace železniční dopravy, záchrana, údržba a provoz historických kolejových vozidel a nemovitostí. Oficiálnímu zaregistrování předcházely aktivity zakládajících členů v oblasti železničního provozu. Jádro sdružení vzešlo z řad strojvedoucích depa Čerčany a jednotícím prvkem a myšlenkou byla vzorná péče o tehdejší „perly“ posázavských lokálek, Zamračené, inventárních čísel 094 a 230. Provozní potřebou byly tyto hýčkané stroje nakonec dislokovány do jiných dep a nám začalo být jasné, že chceme-li v budoucnu jakkoliv zúročit dobrovolnickou práci na lokomotivách a nalézt v péči o ně smysl, nezbývá, než si pořídit vlastní…
                            Počátky našeho spolku provázely také snahy o zprovoznění parní lokomotivy. Symbolem i motivem loga se nám stal „nulkový Čtyřkolák“, lokomotiva 434.0170, zapůjčená od NTM Praha. Jako neprovozní exponát se nám ho v období milénia dařilo prezentovat na mnoha železničních výstavách v rámci jubileí posázavských tratí. Postupem času tuto snahu vystřídaly přeci jen poněkud reálnější a bližší cíle, na odkup a záchranu historických vozidel motorové trakce. Jako první se pod naším „vedením“ navrátil do Posázaví motorový vůz M 262.045 – ale ani nad párou jsme nezlomili hůl…
                            Náš spolek úzce spolupracuje s mnoha subjekty, které parní lokomotivy mají a provozují…
                        </p>
                    </div>
                    <div className={'onas-stats box-shadow-l flex-row center-vertical'}>

                        <div className={'onas-stats-content-div flex-column center-vertical'}>
                            <h2 className={'text-white text-uppercase'}>Vlastníme</h2>
                            <div className={'onas-stats-number-div flex-column center-vertical center-horizontal'}>
                                <h1 className={'text-white'}>{statStroje}</h1>
                                <h1>{statStroje}</h1>
                            </div>
                            <h2 className={'text-white text-uppercase'}>Strojů</h2>
                        </div>
                        <div className={'onas-stats-separator flex-row center-horizontal center-vertical'}>
                            <h1 className={'text-white text-header'}>&</h1>
                        </div>
                        <div className={'onas-stats-content-div flex-column center-vertical'}>
                            <h2 className={'text-white text-uppercase'}>Uspořádali jsme</h2>
                            <div className={'onas-stats-number-div flex-column center-vertical center-horizontal'}>
                                <h1 className={'text-white'}>185</h1>
                                <h1>185</h1>
                            </div>
                            <h2 className={'text-white text-uppercase'}>Jízd</h2>
                        </div>
                    </div>
                    <div className={'onas-popis-text'}>
                        <p className={''}>
                            <b>Naše činnost a cíle ve stručných bodech:</b>
                            <br/><br/>
                            <ul className={"onas-list"}>
                                <li>Propagace železniční dopravy v Posázaví a okolních regionech, ve smyslu jejího
                                    historického významu i perspektivy do budoucna, coby fungujícího a ekologicky
                                    šetrného prostředku mobility obyvatelstva a rekreace:
                                    <ul>
                                        <li>
                                            náš spolek spolupracuje s regionálními muzei na výstavách, naši členové jsou
                                            k dispozici novinářům i televizním štábům při natáčení pořadů o železnici v
                                            Posázaví,
                                            např. Toulavá kamera, Putování s párou, dětské pořady…
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Záchrana, renovace a provoz historicky významných železničních vozidel a utváření
                                    obrazu profilu vývoje techniky v železniční dopravě:
                                    <ul>
                                        <li>
                                            viz sekce „Naše Lokomotivy“
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Organizování jízd zvláštních, nostalgických, výletních a turistických vlaků, v
                                    motorové i
                                    parní trakci, pro skupiny zájemců a v rámci příležitostných akcí a sezónních jízd
                                    pro
                                    veřejnost:
                                    <ul>
                                        <li>
                                            ročně vypravíme na dvě desítky zvláštních vlaků do nejrůznějších turisticky
                                            zajímavých
                                            cílů pro soukromé skupiny i veřejnost. Oblíbeným výletem je např. Adventní
                                            vlak do Třeboně…
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Pořádání a spolupořádání výstav železničních vozidel, modelových kolejišť, dnů
                                    otevřených dveří lokomotivního depa, jakož i dalších kulturních akcí s hudbou i
                                    občerstvením, pro návštěvníky všech věkových kategorií:
                                    <ul>
                                        <li>
                                            v našem domovském depu v Benešově pořádáme tradiční Májové setkání – den
                                            otevřených dveří s výstavou lokomotiv. Spolupořádáme Festival parních
                                            lokomotiv tamtéž. V zimě si s námi můžete zatančit na Country plesu
                                            Železničářů v Čerčanech…
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Zabránění demolici vybraných významných železničních budov v našem okolí, jejich
                                    rekonstrukce a využití v oblasti turistiky a propagace železnice a její historie:
                                    <ul>
                                        <li>
                                            V tomto segmentu činnosti je dosud největším úspěchem záchrana a
                                            rekonstrukce železniční vodárenské věže v Čerčanech, která je ve vyhlášených
                                            dnech zpřístupněna veřejnosti.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className={'onas-stroje flex-column center-vertical'}>

                        <div className={'onas-stroje-showcase flex-column'}>
                            <h2>Naše Lokomotivy</h2>
                            <div className={'onas-stroje-showcase-div flex-row'}>
                                <div className={'onas-carousel flex-row'} id={'carousel'} onScroll={() => {
                                    void checkInView('lokomotiva-', lokomotivy);
                                }}>
                                    {lokomotivy.map((data) => (
                                        <Gondola params={data} type={'lokomotiva-'}
                                                 modalState={modalOpen}
                                                 closeModal={closeModal}
                                                 openModal={openModal}
                                        />
                                    ))}
                                </div>
                                <div className={'onas-carousel-buttons flex-row justify-c-space-between center-vertical'}>
                                    <span className={'pointer flex-row center-vertical center-horizontal box-shadow-s'} onClick={() => {
                                        if (prevLokomotiva !== 0)
                                            document.getElementById('lokomotiva-' + prevLokomotiva).scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
                                    }}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </span>
                                    <span className={'pointer flex-row center-vertical center-horizontal box-shadow-s'} onClick={() => {
                                        document.getElementById('lokomotiva-' + nextLokomotiva).scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
                                    }}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </span>
                                </div>
                            </div>
                            {/*<h2>Naše Vagóny</h2>
                                <div className={'onas-stroje-showcase-div flex-row'}>
                            <div className={'onas-carousel flex-row'} id={'carousel'} onScroll={() => {
                                void checkInView('vagon-', vagony);
                            }}>
                                {vagony.map((data) => (
                                    <Gondola params={data} type={'vagon-'}
                                             modalState={modalOpen}
                                             closeModal={closeModal}
                                             openModal={openModal}
                                    />
                                ))}
                            </div>
                            <div className={'onas-carousel-buttons flex-row justify-c-space-between center-vertical'}>
                                    <span className={'pointer flex-row center-vertical center-horizontal box-shadow-s'}
                                          onClick={() => {
                                              if (prevVagon !== 0)
                                                  document.getElementById('vagon-' + prevVagon).scrollIntoView({
                                                      behavior: 'smooth',
                                                      block: 'nearest',
                                                      inline: 'center'
                                                  });
                                          }}>
                                        <FontAwesomeIcon icon={faAngleLeft}/>
                                    </span>
                                <span className={'pointer flex-row center-vertical center-horizontal box-shadow-s'}
                                      onClick={() => {
                                          document.getElementById('vagon-' + nextVagon).scrollIntoView({
                                              behavior: 'smooth',
                                              block: 'nearest',
                                              inline: 'center'
                                          });
                                      }}>
                                        <FontAwesomeIcon icon={faAngleRight}/>
                                    </span>
                            </div>
                        </div>
                        */}
                        </div>
                    </div>
                </div>

            </motion.div>
        </>
    );
}

export default ONas;