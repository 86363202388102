import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {supabase} from "../../komponenty/utils/supabase";
import CapsuleAdmin from "../../komponenty/blog/CapsuleAdmin";
import AdminImage from "../../komponenty/admin/AdminImage";

function SpravaObrazky() {

    const [obrazky, setObrazky] = useState([]);

    useEffect(() => {
        getAkce();
    }, []);

    async function getAkce() {
        const { data, error } = await supabase
            .storage
            .from("obrazky_upload")
            .list("", {
                limit: 25,
            })

        if (error) {
            console.log(error);
        }

        setObrazky(data);
    }

    return (
        <>
            <Helmet>
                <title>Správa Obrázků | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <Link to={'..'} className={'admin-back flex-row'}>
                    <p className={'text-uppercase text-center'}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Zpět
                    </p>
                </Link>

                <div className={'admin-page flex-column center-vertical'}>

                    <Link to={'nahrat'} className={'admin-dash-button'}>
                        Nahrát obrázek
                    </Link>

                    <div className={'admin-akce flex-column center-vertical'}>
                        {obrazky.map((obrazek) => (
                            <AdminImage image={obrazek} />
                        ))}
                    </div>

                </div>

            </motion.div>
        </>
    )
}

export default SpravaObrazky;