import React, {useEffect, useRef} from "react";
import useScreenSize from "../utils/useScreenSize";
import NavLinks from "./NavLinks";
import {Sling as Hamburger} from 'hamburger-react'
import { motion } from "framer-motion"
import {useState} from "react";
import {NavLink} from "react-router-dom";

const mobileMenuAnimations = {
    open: {
        transform: "translateY(0)",
        opacity: 1,
        display: "flex",
    },
    closed: {
        transform: "translateY(-100%)",
        opacity: .3,
        transitionend: {
            display: "none"
        }
    }
};

function Navbar() {

    const {width} = useScreenSize();
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const lettersChanged = useRef(false);

    function letterChange() {
        let navText = document.querySelector("div .nav-text-header")
        let navTextLetters = navText.textContent.split("");
        navText.textContent = "";
        navTextLetters.forEach((letter, i) => {
            let span = document.createElement('span');
            let delay = i/40;

            let letterOut = document.createElement('span');
            letterOut.textContent = letter;
            letterOut.style.transitionDelay = `${delay}s`;
            letterOut.classList.add('nav-text-header-letter-out');
            if (letter === " ")
                letterOut.classList.add('nav-text-header-space')
            span.append(letterOut);

            let letterIn = document.createElement('span');
            letterIn.textContent = letter;
            letterIn.style.transitionDelay = `${delay}s`;
            letterIn.classList.add('nav-text-header-letter-in');
            span.append(letterIn);

            navText.append(span);
        });
    }

    useEffect(() => {
        if (lettersChanged.current)
            return;
        lettersChanged.current = true;

        letterChange();
        window.scrollTo(0,0);
    }, [])

    return (
        <>
            <div className={"navbar navbar-main"} id={"navbar-main"} style={{ top: "0px" }}>
                <NavLink to={'/'} id={"nav-text"} className={"nav-text-header text-white"}>Posázavský Pacifik</NavLink>
                <nav className={"nav-links"}>
                    {width <= 1050 ?
                        <Hamburger rounded={true} easing={"ease-in-out"} duration={.4} label={'Zobrazit menu'}
                                   toggled={mobileMenuVisible} toggle={setMobileMenuVisible} color={"#F0F0F0"} />
                        : <NavLinks />}
                </nav>
            </div>
            <motion.div className={"hamburger-menu"}
                        animate={mobileMenuVisible ? "open" : "closed"}
                        transition={{ ease: "easeOut", duration: .4 }}
                        initial={"closed"}
                        variants={mobileMenuAnimations}
                        onClick={() => {
                            setTimeout(() => {
                                setMobileMenuVisible(false)
                            }, 100)
                        }}
            >
                <NavLinks />
            </motion.div>
        </>
    );
}

export default Navbar;