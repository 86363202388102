import React from "react";


export default function Gondola({params, type, openModal, closeModal, modalState}) {

    return (
      <>
          <div className={'onas-carousel-gondola'} id={type + params.id}>
              <div className={'onas-carousel-gondola-image box-shadow-l pointer flex-row'}
                   style={{ backgroundImage: 'url(' + params.obrazek + ')' }}
                   onClick={() => (modalState ? closeModal() : openModal(params))}
              >
                  <p className={'text-white'}>{params.nazev}</p>
              </div>
          </div>
      </>
    );

}