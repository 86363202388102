import {useEffect, useState} from "react";
import {supabase} from "../komponenty/utils/supabase";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import Capsule from "../komponenty/blog/Capsule";

function Aktuality() {

    const [aktuality, setAktuality] = useState([]);

    useEffect(() => {
        void getAktuality();
    }, []);

    async function getAktuality() {
        const { data } = await supabase
            .from("aktuality")
            .select()
            .order('id', { ascending: false });

        setAktuality(data);
    }

    return (
        <>
            <Helmet>
                <title>Aktuality | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div className={'akce-main'}>
                    <h1 className={'page-header text text-center'}>Aktuality</h1>
                    <div className={'flex-column center-vertical'}>
                        {aktuality.map((preview) => (
                            <Capsule data={preview} additions={' blog-mobile-divider-normal-bg'} type={1} />
                        ))}
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default Aktuality;