import React from "react";
import useScreenSize from "../utils/useScreenSize";
import Content from "./Content";
import ContentMobile from "./ContentMobile";

export default function Capsule({deco, decoPos, data, additions, type}) {

    const {width} = useScreenSize();

    return (
        <>
            {width <= 700 ?

                <div className={'blog-mobile-main box-shadow-l'}>
                   <ContentMobile data={data} addition={additions} type={type}/>
                </div>

                :

                <div className={"blog-preview-capsule margin-s"}>
                    <div className={"blog-preview-main box-shadow-l"}>
                        <Content data={data} type={type}/>
                    </div>
                    {deco === true ?
                        <div className={decoPos === 'left' ? 'blog-preview-deco blog-preview-deco-left' : 'blog-preview-deco blog-preview-deco-right'} />
                        : null}
                </div>

            }
        </>
    );

}