import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import {supabase} from "../../komponenty/utils/supabase";

function Login() {

    const [msg, setMsg] = useState('');
    const [msgClass, setMsgClass] = useState('');
    const [loginButton, setLoginButton] = useState('text-white login-button');
    const [buttonText, setButtonText] = useState('Pokračovat');

    async function loginUser(e) {
        e.preventDefault();

        setLoginButton('text login-button login-button-verify');
        setButtonText('Ověřování...');
        setMsg('');

        const { error } =
            await supabase.auth.signInWithOtp({
                email: e.target[0].value,
                options: {
                    redirectTo: 'https://pacifik.entaria.net/admin',
                },
            })

        if (error) {
            setMsg('Během přihlášení nastal error: '+ error);
            setTimeout(function() {
                window.location.reload();

            }, 5000);
            setMsgClass('login-text-error');
            setButtonText('Ověření selhalo ❌');
        } else {
            setMsgClass('login-text-success');
            setMsg('Na Váš email byl zaslán odkaz pro přihlášení. \n Tuto kartu můžete zavřít.' );
            setButtonText('Ověřeno ✅');
        }
    }

    return (
        <>
            <Helmet>
                <title>Login | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div className={'login-page flex-row center-vertical center-horizontal'}>
                    <form onSubmit={loginUser} className={'login-container box-shadow-s flex-column center-vertical'}>
                        <h1>Přihlášení</h1>
                        <p  className={'text-center text-uppercase'}>Pokračováním souhlasíte s uložením <b><a href={'https://cs.wikipedia.org/wiki/HTTP_cookie'}>cookies</a></b> 🍪</p>

                        <div className={'flex-column'}>
                            <label>Email</label>
                            <input
                                type={'email'}
                                name={'email'}
                                className={'text-center text'}
                                required
                            />
                        </div>
                        {//TODO Disable button during verification
                            }
                        <button className={loginButton}>{buttonText}</button>
                        <b className={`text-center ${msgClass}`}>{msg}</b>

                    </form>
                </div>
            </motion.div>
        </>
    )
}

export default Login;