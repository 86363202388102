import {supabase} from "./supabase";
import {useEffect, useState} from "react";

function AuthVerify (){

    const [authenticated, setAuthenticated] = useState(-1);

    useEffect(() => {
        const verifySession = async () => {
            supabase.auth.getSession().then(({data: {session}}) => {
                if (!session) {
                    setAuthenticated(0);
                } else {
                    setAuthenticated(1);
                }
            })
        };
        void verifySession();
    }, []);

    return authenticated;

}

export default AuthVerify;