import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import Capsule from "../../komponenty/blog/Capsule";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {supabase} from "../../komponenty/utils/supabase";
import toast from "react-hot-toast";

function NahratObrazek() {

    const navigate = useNavigate();

    const [imageLink, setImageLink] = useState('');
    const [image, setImage] = useState();

    let saveToast;

    useEffect(() => {
        setImageLink('/img/bg.jpg')
    }, [])

    // noinspection JSCheckFunctionSignatures
    async function readImage(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();reader.onloadend = async function () {
                setImageLink(reader.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    function saveImage(input) {
        if (input.files && input.files[0]) {
            setImage(input.files[0]);
        }
    }

    async function saveArticle() {

        if (
            image == null
        ) {
            toast.error(() => (
                <span>
                    Prosím nahrajte obrázek
                </span>
            ));
            return;
        }

        saveToast = toast.loading('Ukládání...')

        void uploadFile();
    }

    async function uploadFile() {
        const { error } = await supabase.storage
            .from('obrazky_upload')
            .upload(Date.now()*2 + '_' + image.name.replace(' ', '_').replace(";", "-"), image, {
                cacheControl: '604800'
            })

        if (error) {
            toast.error('Uložení selhalo', {
                id: saveToast
            });
            console.log(error);
        } else {
            toast.success('Obrázek byl uložen!', {
                id: saveToast
            });

            setTimeout(function(){
                navigate('..')
            }, 1000);
        }
    }

    return (
        <>
            <Helmet>
                <title>Nahrát Obrázek | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <Link to={'..'} className={'admin-back flex-row'}>
                    <p className={'text-uppercase text-center'}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Zpět
                    </p>
                </Link>

                <div className={'admin-page admin-akce-new'}>

                    <div className={'aan-fields flex-column'}>

                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Média</h2>
                            <input type={"file"} accept={'image/png,image/jpeg,image/webp'} onChange={(e) => {
                                void readImage(e.target);
                                saveImage(e.target);
                            }}/>
                        </div>
                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Správa</h2>
                            <div className={'flex-row'}>
                                <p className={'admin-dash-button text-center'} onClick={saveArticle}>
                                    Uložit
                                </p>
                                <Link to={'/admin/akce'} className={'admin-dash-button text-center'}>
                                    Zrušit
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className={'aan-preview-full'}>
                        <div className={'blog-read-header flex-column center-vertical'}>
                            <img src={imageLink}
                                 alt={'Obrázek'}
                                 style={{ maxWidth: '80%' }}
                            />
                        </div>
                    </div>

                </div>

            </motion.div>
        </>
    )
}

export default NahratObrazek;