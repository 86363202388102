import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const Modal = ({ params, close }) => {

    return (
        <>
            <div className={'modal-top flex-row justify-c-space-between'}>
                <h1>{params.nazev}</h1>
                <FontAwesomeIcon icon={faTimesCircle} className={'pointer'} onClick={close} />
            </div>
            <div className={'modal-bottom flex-column'}>
                <div className={'modal-text'}>
                    <p className={'text-justify text'}>
                        {params.popis}
                    </p>
                </div>
                <div
                    className={'modal-image'}
                    style={{ backgroundImage: 'url(' + params.obrazek + ')', backgroundPositionY: params.pos + '%' }}
                ></div>
            </div>
        </>
    );
}

export default Modal;