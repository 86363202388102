import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faTelegram, faTwitter, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {faAngleRight, faLink} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import toast from "react-hot-toast";

function Footer() {

    const location = window.location.href.toString();
    const text = 'Pojďte se podívat na web Posázavského Pacifiku!';

    function copyToClipboard() {
        navigator.clipboard.writeText(location);
        toast.success('Zkopírováno do schránky!', {
            id: 'clipboard',
            position: "bottom-center",
        })
    }

    return (
        <>
            <footer className={'flex-row center-vertical justify-c-space-around'}>
                <div className={'center-vertical flex-column justify-c-space-evenly capsule-div'}>
                    <h2 className={'text-uppercase text-white text-center'}>Domluvte si jízdu!</h2>
                    <div className={'flex-row center-horizontal center-vertical footer-partner-div'}>

                        <Link to={'/kontakt'}>

                            <p  className={'text-white text-uppercase'}>
                                Najít jízdu <FontAwesomeIcon icon={faAngleRight} />
                            </p>

                        </Link>
                    </div>
                </div>
                <Link to={'/'}
                    className={'footer-image-link'}
                >
                    <img src={'/img/logo.webp'}
                         alt={'Logo Posázavského Pacifiku'}
                    />
                </Link>
                <div className={'center-vertical flex-column justify-c-space-evenly capsule-div'}>
                    <h2 className={'text-uppercase text-white text-center'}>Sdílejte tuto stránku!</h2>
                    <div className={'flex-row justify-c-space-between'}>
                        <p onClick={copyToClipboard} className={'pointer'}>
                            <FontAwesomeIcon className={'fa'} icon={faLink} />
                        </p>
                        <a href={`https://twitter.com/intent/tweet/?text=${text}&url=${location}`} target={"_blank"}  rel={"noreferrer"}>
                            <FontAwesomeIcon className={'fa'} icon={faTwitter} />
                        </a>
                        <a href={`https://api.whatsapp.com/send?text=${text} ${location}`} target={"_blank"}  rel={"noreferrer"}>
                            <FontAwesomeIcon className={'fa'} icon={faWhatsapp} />
                        </a>
                        <a href={`https://www.facebook.com/sharer.php?u=${location}`} target={"_blank"} rel={"noreferrer"}>
                            <FontAwesomeIcon className={'fa'} icon={faFacebook} />
                        </a>
                        <a href={`https://telegram.me/share/url?url=${text} ${location}`} target={"_blank"}  rel={"noreferrer"}>
                            <FontAwesomeIcon className={'fa'} icon={faTelegram} />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
