import React from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";

function Kontakt() {

    return (
        <>
            <Helmet>
                <title>Kontakt | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div className={'kontakt-bg-div flex-row center-vertical center-horizontal'}>
                    <div className={'kontakt-main flex-column center-vertical box-shadow'}>

                        <h1  className={'page-header text-white'}>Kontakt</h1>
                        <p  className={'text-uppercase text-white'}>Aneb kde nás najít</p>

                        <div className={'kontakt-grid'}>
                            <div className={'flex-row center-vertical center-horizontal'}>
                                <iframe
                                    className={'kontakt-map box-shadow-l'}
                                    title={'Posázvský Pacifik, Čerčany'}
                                    src="https://frame.mapy.cz/s/hemaralare"
                                    width="400" height="400"
                                />
                            </div>
                            <div className={'flex-column center-horizontal center-vertical justify-c-space-evenly'}>
                                <div className={'flex-column center-vertical center-horizontal kontakt-tile-white box-shadow-s'}>
                                    <h2 className={'text-uppercase'}>Adresa</h2>
                                    <p  className={'kontakt-margin text-center'}>Nádražní 25, 257 22 Čerčany, Česko</p>
                                </div>
                                <div className={'flex-column center-vertical center-horizontal kontakt-tile-whiter box-shadow-s'}>
                                    <h2 className={'text-uppercase'}>IČO</h2>
                                    <p  className={'kontakt-margin text-center'}>67983570</p>
                                </div>
                                <div className={'flex-column center-vertical center-horizontal kontakt-tile-white box-shadow-s'}>
                                    <h2 className={'text-uppercase'}>DIČ</h2>
                                    <p  className={'kontakt-margin text-center'}>CZ67983570</p>
                                </div>
                            </div>
                            <div className={'flex-column center-horizontal center-vertical justify-c-space-evenly'}>
                                <div className={'flex-column center-vertical center-horizontal kontakt-tile-whiter box-shadow-s'}>
                                    <h2 className={'text-uppercase'}>E-Mail</h2>
                                    <p  className={'kontakt-margin text-center'}>posazavsky.pacifik@seznam.cz</p>
                                </div>
                                <div className={'flex-column center-vertical center-horizontal kontakt-tile-white box-shadow-s'}>
                                    <h2 className={'text-uppercase'}>Facebook</h2>
                                    <a href={'https://www.facebook.com/100064783593880'} target={"_blank"} className={'kontakt-margin text-center underline'}>Posázavský Pacifik</a>
                                </div>
                                <div className={'flex-column center-vertical center-horizontal kontakt-tile-whiter box-shadow-s'} style={{ display: 'none' }}>
                                    <h2 className={'text-uppercase'}>Instagram</h2>
                                    <a href={'někde něco'} target={'_blank'} className={'kontakt-margin text-center underline'}>@něco (asi ne posazavsky_pacifik, protože to vlastní nějaký číňani)</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default Kontakt;