import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {supabase} from "../../komponenty/utils/supabase";
import CapsuleAdmin from "../../komponenty/blog/CapsuleAdmin";

function SpravaStroje() {

    const [akce, setAkce] = useState([]);

    useEffect(() => {
        getAkce();
    }, []);

    async function getAkce() {
        const { data } = await supabase
            .from("akce")
            .select()
            .order('id', { ascending: false });

        setAkce(data);
    }

    return (
        <>
            <Helmet>
                <title>Správa Strojů | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <Link to={'..'} className={'admin-back flex-row'}>
                    <p className={'text-uppercase text-center'}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Zpět
                    </p>
                </Link>

                <div className={'admin-page flex-column center-vertical'}>

                    <Link to={'novy'} className={'admin-dash-button'}>
                        Přidat stroj
                    </Link>

                    <div className={'admin-akce flex-column center-vertical'}>
                        {akce.map((preview) => (
                            <CapsuleAdmin data={preview} demo={true} />
                        ))}
                    </div>

                </div>

            </motion.div>
        </>
    )
}

export default SpravaStroje;