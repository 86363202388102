import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import React from "react";

export default function ContentMobile({data, addition, type}) {

    //TODO: Na mobilu text overflowuje z divu, fixnout

    return (
        <>
            <div className={'blog-mobile-img-div'}>
                <img src={data.img} alt={'Náhledový obrázek'}/>
            </div>
            <div className={'blog-mobile-divider' + (addition ? addition : '')}>
                <span className={'blog-mobile-cutout' + (addition ? addition : '')} />
                <span className={'blog-mobile-cutout' + (addition ? addition : '')} />
                <span className={'blog-mobile-cutout' + (addition ? addition : '')} />
                <span className={'blog-mobile-cutout' + (addition ? addition : '')} />
                <span className={'blog-mobile-cutout' + (addition ? addition : '')} />
            </div>
            <div className={'blog-mobile-content'}>
                <h1 className={"text-uppercase"}>{data.nadpis}</h1>
                <p  className={'content'}>{data.popis}</p>
                <Link to={`/${type === 1 ? 'aktuality' : 'akce' }/${data.nadpis}.${data.id}`} className={'blog-mobile-button-div'}>
                    <p  className={'text-white text-uppercase'}>Číst dále</p>
                    <motion.span className="arrow text"
                                 transition={{ repeat: Infinity, duration: 2 }}
                                 animate={{opacity: [.2,1,.2] }}>
                    </motion.span>
                </Link>
            </div>
        </>
    );
}