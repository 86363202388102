import {Helmet} from "react-helmet";
import Capsule from "../komponenty/blog/Capsule";
import {supabase} from "../komponenty/utils/supabase";
import {useEffect, useState} from "react";
import {motion} from "framer-motion";

function Akce() {

    const [akce, setAkce] = useState([]);

    useEffect(() => {
        void getAkce();
    }, []);

    async function getAkce() {
        const { data } = await supabase
            .from("akce")
            .select()
            .order('id', { ascending: false });

        setAkce(data);
    }

    return (
        <>
            <Helmet>
                <title>Akce | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div className={'akce-main'}>
                    <h1 className={'page-header text text-center'}>Akce</h1>
                    <div className={'flex-column center-vertical'}>
                        {akce.map((preview) => (
                            <Capsule data={preview} additions={' blog-mobile-divider-normal-bg'} />
                        ))}
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default Akce;