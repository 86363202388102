import React, {useEffect, useState} from "react";
import {supabase} from "../utils/supabase";
import {Link, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

export default function AdminImage({image}) {

    const [data, setData] = useState();

    const navigate = useNavigate();

    let daToast;

    useEffect(() => {
        async function getInfo(image) {
            const { data } = supabase
                .storage
                .from('obrazky_upload')
                .getPublicUrl(image.name)

            setData(data.publicUrl)
        }

        void getInfo(image)
    }, [image]);

    function copyToClipboard() {
        navigator.clipboard.writeText(data);
        toast.success('Zkopírováno do schránky!', {
            id: 'clipboard',
            position: "top-center",
        })
    }

    async function askToDelete() {
        daToast = toast((t) => (
            <span className={'flex-column center-vertical'}>
                Opravdu chcete odstranit obrázek
                <span><b>{image.name}</b>?</span>
                <div className={'flex-row center-horizontal margin-s'}>
                    <p className={'admin-dash-button'} onClick={deleteImage}>
                        Ano
                    </p>
                    <p className={'admin-dash-button'} onClick={() => toast.dismiss(t.id)}>
                        Ne
                    </p>
                </div>
            </span>
        ), {
            duration: 8000,
        });
    }

    async function deleteImage() {

        toast.loading('Ničím molekuly obrázku...', {
            id: daToast,
        });

        const { error } = await supabase
            .storage
            .from('obrazky_upload')
            .remove([image.name])

        if (error) {
            toast.error('Něco se nepovedlo', {
                id: daToast,
            });
            console.log(error);
        } else {
            toast.success('Úspěšně odstraněno!', {
                id: daToast,
            });
            setTimeout(function () {
                navigate(0);
            }, 2000);
        }
    }

    return (
        <>
            <div style={{maxWidth: "800px", marginTop: "25px"}} className="admin-dash-tile box-shadow-s center-vertical center-horizontal flex-column justify-c-center">
                <img src={data} alt={image.name} style={{maxWidth: "90%"}}/>
                <div className="flex-row justify-c-center" style={{marginTop: "20px"}}>
                    <p className={'admin-dash-button'} onClick={(e) => {
                        void copyToClipboard();
                    }} >
                        Odkaz
                    </p>
                    <p className={'admin-dash-button'} onClick={(e) => {
                        void askToDelete();
                    }} >
                        Smazat
                    </p>
                </div>
            </div>
        </>
    );

}