import React from "react";

function SmallNav() {

    async function scrollUp() {
        if (document.body.style.overflowY !== 'hidden')
            window.scroll(0, 0);
    }

    return (
        <>
            <div className={"navbar navbar-small"} id={"navbar-small"} style={{ top: "-30px" }}>
                <p onClick={scrollUp} className={"navbar-click-top pointer"}>
                    <span className={"arrow"} style={{ rotate: "180deg", margin: "0", marginTop: "9px",
                    width: "12px", height: "12px", borderWidth: "0 4px 4px 0", borderRadius: "2px" }}></span>
                </p>
            </div>
        </>
    );
}

export default SmallNav;