import React from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";

function Jizdy() {
    return (
        <>
            <Helmet>
                <title>Sponzoři | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div className={'jizdy-main'}>
                    <h1 className={'page-header text text-center'}>Jízdy</h1>
                </div>
            </motion.div>
        </>
    )
}

export default Jizdy;