import {NavLink} from "react-router-dom";
import React from "react";
import {motion} from "framer-motion";
import AuthVerify from "../utils/authVerify";

function NavLinks() {

    let authenticated = AuthVerify();

    const hover = {
        y: -5,
    }

    function allowScroll() {
        document.body.style.overflowY = 'initial';
    }

    return (
        <>
            <div onClick={allowScroll}>
                <NavLink to={'/'} className={"navbar-link text-center"}>
                    <motion.li
                        whileHover={hover}>
                        Domů
                    </motion.li>
                </NavLink>
                <NavLink to={'/akce'} className={"navbar-link text-center"}>
                    <motion.li
                        whileHover={hover}>
                        Akce
                    </motion.li>
                </NavLink>
                <NavLink to={'/aktuality'} className={"navbar-link text-center"}>
                    <motion.li
                        whileHover={hover}>
                        Aktuality
                    </motion.li>
                </NavLink>
                {
                    //TODO: Pak tohle odstranit
                    authenticated !== 1 ? null :
                <NavLink to={'/jizdy'} className={"navbar-link text-center"}>
                    <motion.li
                        whileHover={hover}>
                        Jízdy
                    </motion.li>
                </NavLink>
                }
                <NavLink to={'/o-nas'} className={"navbar-link text-center"}>
                    <motion.li
                        whileHover={hover}>
                        O Nás
                    </motion.li>
                </NavLink>
                <NavLink to={'/kontakt'} className={"navbar-link text-center"}>
                    <motion.li
                        whileHover={hover}>
                        Kontakt
                    </motion.li>
                </NavLink>

                {
                    authenticated !== 1 ? null :
                        <NavLink to={'/admin'} className={"navbar-link text-center"}>
                            <motion.li
                                whileHover={hover}>
                                Admin
                            </motion.li>
                        </NavLink>
                }
            </div>

        </>
    )
}

export default NavLinks;