import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import React from "react";

export default function Content({data, demo, type}) {

    return (
        <>
            <div className={"blog-preview-inside"}>
                <div className={"blog-preview-left"}>
                    <div className={"text-div"}>
                        <div className={"header-div"}>
                            <h1 className={"text-white"}>{data.nadpis}</h1>
                            <p className={"text-white"}>{data.datum}</p>
                        </div>
                        <p className={"text-white"}>{data.popis}</p>
                    </div>
                    <img className={"blog-preview-img-left"} src={data.img} alt={"Náhledový obrázek"}/>
                </div>
                <div className={"blog-preview-right"}>
                    {
                        demo ? null :
                            <Link to={data.link_only === 'true' ? data.obsah : `/${type === 1 ? 'aktuality' : 'akce' }/${data.nadpis}.${data.id}`} className={"text-div blog-preview-link"}>
                                <p className={"text-white"}>Zjistit více</p>
                                <motion.span className="arrow"
                                             transition={{repeat: Infinity, duration: 2}}
                                             animate={{opacity: [.2, 1, .2]}}>
                                </motion.span>
                            </Link>
                    }
                    <img className={"blog-preview-img-right"} src={data.img} alt={"Náhledový obrázek"}/>
                </div>
            </div>
        </>
    );
}