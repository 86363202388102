import {Navigate} from "react-router-dom";
import AuthVerify from "./authVerify";

export const AuthRoute = ({ children, login }) => {

    let authenticated = AuthVerify();


    switch (login) {
        case true: {
            return authenticated === 1 ? (
                <Navigate to='/admin' />
            ) : authenticated === 0 ? (
                children
            ) : null;
        }

        default: {
            return authenticated === 1 ? (
                children
            ) : authenticated === 0 ? (
                <Navigate to='/admin/login' />
            ) : null;
        }

    }

};