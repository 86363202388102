import React from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";

function Stroje() {
    return (
        <>
            <Helmet>
                <title>Stroje | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

            </motion.div>
        </>
    )
}

export default Stroje;